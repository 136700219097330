import Headroom from "headroom.js";

$(document).on('turbo:load', function(){
  $(document).on('scroll', function () {
    if(!document.location.toString().includes('chat_soc')) {
      var state = $(document).scrollTop() > 45;
      $('header').toggleClass('small', state);
      $('#search').toggleClass('small', state);
      $('main').toggleClass('small', state);
    }
  });

 if(document.URL.includes('chat') || document.URL.includes('calculators')) {
   return
 }

  var myElement = document.querySelector("header");
  if(myElement != null && myElement != undefined) {
    var headroom  = new Headroom(myElement);
    headroom.init();
  }

  var myElement = document.querySelector("#search");
  if(myElement != null && myElement != undefined) {
    var headroom  = new Headroom(myElement);
    headroom.init();
  }
});
