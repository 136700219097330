// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbo:load', function(){
$(".transcript-toggle-container").hide();
$("div.transcriptReveal").click(function(){
    $(this).toggleClass("active").next().slideToggle("fast");

    if ($.trim($(this).text()) === 'View Live Transcript') {
        $(this).text('Hide Live Transcript');
    } else {
        $(this).text('View Live Transcript');
    }

    return false;
});
 $("a[href='" + window.location.hash + "']").parent(".transcriptReveal").click();
});

$(document).on('turbo:load', function(){
$(".topics-toggle-container").hide();
$("p.topicsReveal").click(function(){
    $(this).toggleClass("active").next().slideToggle("fast");

    if ($.trim($(this).text()) === 'Hide ▲') {
        $(this).text('Show ▼');
    } else {
        $(this).text('Hide ▲');
    }

    return false;
});
 $("a[href='" + window.location.hash + "']").parent(".topicsReveal").click();
});

