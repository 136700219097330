import Mark from "mark.js"

$(document).on('turbo:load', function(){
  var context = document.getElementById('main-content')
  if(context != null && context != undefined) {
    var instance = new Mark(context);

    const limiters = ":;.,-–—‒_(){}[]!'\"+=’".split("")
    if(localStorage.getItem("searchQuery")) {
      const queryItems = JSON.parse(localStorage.getItem("searchQuery"))
      localStorage.removeItem("searchQuery")

      for(const item of queryItems)
        instance.mark(item,
                      { "accuracy": { "value": "exactly", "limiters": limiters },
                        "acrossElements": true,
                        "separateWordSearch": false,
                        "ignoreJoiners": true,
                        "ignorePunctuation": limiters
                      });
    }
  }
});
