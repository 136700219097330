import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['annualButton', 'monthlyButton', 'twoMembersButton', 'fiveMembersButton', 'twentyFiveMembersButton',
                    'perUserSavings', 'billedSavingsOriginal', 'billedSavingsDiscounted', 'teamPrice', 'userPrice',
                    'annualDiscountUser', 'annualDiscountTeam', 'checkoutUrl', 'contactUrl']

  connect() {
    this.annualSelected = true;
    this.selectedMembers = 2
    this.selectAnnualPayment()
    this.select2Members()
  }

  selectMonthlyPayment() {
    this.annualSelected = false
    this.annualButtonTarget.className = "period-btn-clear"
    this.monthlyButtonTarget.className = "period-btn"
    this.annualDiscountUserTarget.style = "display: none"
    this.annualDiscountTeamTarget.style = "display: none"
    this.userPriceTarget.innerHTML = "$66"
    this.checkoutUrlTarget.href = "/checkout?item=8"
    if(this.selectedMembers == 2) {
      this.select2Members()
    }
    if(this.selectedMembers == 5) {
      this.select5Members()
    }
    if(this.selectedMembers == 25) {
      this.select25Members()
    }
  }

  selectAnnualPayment() {
    this.annualSelected = true;
    this.annualButtonTarget.className = "period-btn"
    this.monthlyButtonTarget.className = "period-btn-clear"
    this.annualDiscountUserTarget.style = "display: block"
    this.annualDiscountTeamTarget.style = "display: block"
    this.userPriceTarget.innerHTML = "$55.25"
    this.checkoutUrlTarget.href = "/checkout?item=2"
    if(this.selectedMembers == 2) {
      this.select2Members()
    }
    if(this.selectedMembers == 5) {
      this.select5Members()
    }
    if(this.selectedMembers == 25) {
      this.select25Members()
    }
  }

  select2Members() {
    this.selectedMembers = 2
    this.twoMembersButtonTarget.className = "in-btn"
    this.fiveMembersButtonTarget.className = "in-btn-clear"
    this.twentyFiveMembersButtonTarget.className = "in-btn-clear"
    this.billedSavingsOriginalTarget.innerHTML = "$648"
    this.billedSavingsDiscountedTarget.innerHTML = "$546"
    this.perUserSavingsTarget.innerHTML = "$102"
    if(this.annualSelected) {
      this.teamPriceTarget.innerHTML = "$46 / User / Month"
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+2-4+member+annual+subscription+plan"
      this.annualDiscountTeamTarget.style = "display: block"
    } else {
      this.teamPriceTarget.innerHTML = "$55 / User / Month"
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+2-4+member+monthly+subscription+plan"
      this.annualDiscountTeamTarget.style = "display: none"
    }
  }

  select5Members() {
    this.selectedMembers = 5
    this.twoMembersButtonTarget.className = "in-btn-clear"
    this.fiveMembersButtonTarget.className = "in-btn"
    this.twentyFiveMembersButtonTarget.className = "in-btn-clear"
    this.billedSavingsOriginalTarget.innerHTML = "$552"
    this.billedSavingsDiscountedTarget.innerHTML = "$465"
    this.perUserSavingsTarget.innerHTML = "$87"
    if(this.annualSelected) {
      this.teamPriceTarget.innerHTML = "$39 / User / Month"
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+5-24+member+annual+subscription+plan"
      this.annualDiscountTeamTarget.style = "display: block"
    } else {
      this.teamPriceTarget.innerHTML = "$46 / User / Month"
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+5-24+member+monthly+subscription+plan"
      this.annualDiscountTeamTarget.style = "display: none"
    }
  }

  select25Members() {
    this.selectedMembers = 25
    this.twoMembersButtonTarget.className = "in-btn-clear"
    this.fiveMembersButtonTarget.className = "in-btn-clear"
    this.twentyFiveMembersButtonTarget.className = "in-btn"
    this.annualDiscountTeamTarget.style = "display: none"
    this.teamPriceTarget.innerHTML = "Custom Pricing"
    if(this.annualSelected) {
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+25+or+more+member+annual+subscription+plan"
    } else {
      this.contactUrlTarget.href = "https://www.sullivanoncomp.com/contact-sales?message=I%27d+like+to+learn+more+about+the+25+or+more+member+monthly+subscription+plan"
    }
  }
}
