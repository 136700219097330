$(document).on('turbo:load', function(){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const target = urlParams.get('anchor')
  if(target) {
    if(target.length > 1) {
      $('header').toggleClass('small');
      $('#search').toggleClass('small');
      $('main').toggleClass('small');

      const targetElement = document.getElementById(target)
      if(targetElement) {
        let offset = targetElement.offsetTop

        if(window.innerWidth >= 960) {
          offset = offset + 40
        }
        else if(window.innerWidth >= 480 && window.innerWidth < 960) {
          offset = offset + 95
        }
        else if(window.innerWidth < 480) {
          offset = offset + 20
        }

        $('html, body').animate({
          scrollTop: offset
        }, 700, 'swing', function () {});

      }
    }

  }
})
