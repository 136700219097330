function requireAll(r) { r.keys().forEach(r); }

import $ from "jquery"
import { Turbo } from "@hotwired/turbo-rails";
Turbo.setFormMode("on")
import Mark from "mark.js"
import "./controllers/"

requireAll(require.context('./application/', true, /\.js$/));

