import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "repealedModal", "repealedText", "repealedModalContent", "revisedModal", "revisedModalContent", "revisedText"]

  connect() {
    this.url_href = "#"
    useClickOutside(this, { element: this.repealedModalContentTarget, eventType: 'click' })
    useClickOutside(this, { element: this.revisedModalContentTarget, eventType: 'click' })
  }

  clickOutside(event) {
    if(event.target.id == "repealedModal" || event.target.id == "revisedModal") {
      this.hideModal();
    }
  }

  showRepealedModal() {
    this.repealedModalTarget.classList.add('show')
    this.repealedModalTarget.style.display = 'block'
  }

  showRevisedModal() {
    this.revisedModalTarget.classList.add('show')
    this.revisedModalTarget.style.display = 'block'
  }

  hideModal() {
    this.revisedModalTarget.classList.remove('show')
    this.revisedModalTarget.style.display = 'none'
    this.repealedModalTarget.classList.remove('show')
    this.repealedModalTarget.style.display = 'none'
  }

  updateRevisedText(text) {
    this.revisedTextTarget.innerText = text
  }

  updateRepealedText(text) {
    this.repealedTextTarget.innerText = text
  }

  openRevisedLink(event) {
    window.open(this.url_href, '_blank').focus();
  }

  linkClicked(event) {
    let params = event.params

    let that = this;

    $.get("/repealed_external_resources/?type=" + params.type + "&number=" + params.number, function (data) {
      if (data.repealed && data.revised) {
        that.showRevisedModal();
        that.url_href = event.target.href
        that.updateRevisedText(data.type + ' ' + params.number + ' has been revised since it was first created. Only the current version is available for review.');
      }
      else if (data.repealed) {
        that.showRepealedModal();
        let repealedText = data.type + ' ' + params.number + ' has been repealed and is no longer available.';
        that.updateRepealedText(repealedText);
        if (data.title !== undefined && data.title !== null) {
          that.updateRepealedText(data.title + ' ' + repealedText);
        }
      } else {
        var url = event.target.href;
        if (url === undefined || url === null || url === "") {
          url = event.target.parentElement.href;
        }
        window.open(url, '_blank').focus();
      }
    });
  }
}
