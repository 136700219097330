$(document).on('turbo:load', function(){
  const hamburger = document.querySelector('.hamburger');
  const menu = document.querySelector('.menu');
  const mainNavigation = document.querySelector('#mainNavigation')

  // Toggle menu visibility when hamburger is clicked

  if(hamburger) {
    hamburger.onclick = function() {
      console.log('enable')
      menu.classList.toggle('show');
      mainNavigation.style = 'display: block'
    };

    // Hide menu when clicking outside of it
    document.onclick = function(event) {
      console.log('disable')
      if (!menu.contains(event.target) && !hamburger.contains(event.target)) {
        menu.classList.remove('show');
        mainNavigation.style = ''
      }
    };
  }
});
