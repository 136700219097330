var PDCalculator = (function() {
  function OcupationMatchStart(params, data) {
    // If there are no search terms, return all of the data
    if ($.trim(params.term) === "") {
      return data;
    }

    // Skip if there is no 'children' property
    if (typeof data.children === "undefined") {
      return null;
    }

    // `data.children` contains the actual options that we are matching against
    var filteredChildren = [];
    $.each(data.children, function(idx, child) {
      if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) !== -1) {
        if (child.text.toUpperCase().indexOf("GROUP") === 0) {
          filteredChildren.unshift(child);
        } else {
          filteredChildren.push(child);
        }
      }
    });

    // This loop was added by Valentin Lazar for Occupation select:
    // If we have a group name 'Installers' and in search we type 'install'
    // we will show all options which are under 'Installers'
    $.each(data.children, function(idx, child) {
      if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) !== -1) {
        if (filteredChildren.indexOf(child) === -1) {
          if (data.text.toUpperCase().indexOf("GROUP") === 0) {
            filteredChildren.unshift(child);
          } else {
            filteredChildren.push(child);
          }
        }
      }
    });

    $.each(data.children, function(idx, child) {
      if (
        String(child.element.dataset.groupNumber)
          .toUpperCase()
          .indexOf(params.term.toUpperCase()) !== -1
      ) {
        if (filteredChildren.indexOf(child) === -1) {
          if (child.text.toUpperCase().indexOf("GROUP") === 0) {
            filteredChildren.unshift(child);
          } else {
            filteredChildren.push(child);
          }
        }
      }
    });

    // If we matched any of the timezone group's children, then set the matched children on the group
    // and return the group object
    if (filteredChildren.length) {
      var modifiedData = $.extend({}, data, true);
      modifiedData.children = filteredChildren;

      // You can return modified objects from here
      // This includes matching the `children` how you want in nested data sets
      return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
  }

  function initOccupationSelect2Dropdown() {
    $(".should_be_select2_occupation").select2({
      matcher: OcupationMatchStart,
      width: "100%",
      containerCssClass: "styled-select"
    });

    $(".select2-selection__arrow").hide();
    $(".styled-select").css("border-radius", "0px");
  }

  function initOccupationCodeGroupsPage() {
    initOccupationSelect2Dropdown();
  }

  return {
    initOccupationCodeGroupsPage: initOccupationCodeGroupsPage
  };
})();

document.addEventListener('turbo:load', function () {
  var $occupationCodeGroup = $(".occupation-code-groups");

  if ($occupationCodeGroup.length) {
    PDCalculator.initOccupationCodeGroupsPage();
  }
});
