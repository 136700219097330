document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll('.submit-form-link').forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault();

      const form = link.closest('form');
      if (form) {
        form.submit();
      }
    });
  });
});
