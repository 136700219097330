import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['iframe', 'modal', 'restrictedNotice']

  connect() {
    const customToken = document.getElementById('chatSocInformation').dataset.firebaseToken
    const iframeUrl = document.getElementById('chatSocInformation').dataset.chatSocIframeUrl
    const betaUser = document.getElementById('chatSocInformation').dataset.betaUser

    const modalData = this.modalTarget.dataset

    this.iframeTarget.onload = function(e) {
      e.target.contentWindow.postMessage({ chatSocToken: customToken, isBetaUser: betaUser }, iframeUrl);
    }

    if(modalData.enabledChatSoc == 'false') {
      this.modalTarget.style.display = 'block'
      this.modalTarget.classList.add('show')
    }
  }

  hideModal() {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'

    this.restrictedNoticeTarget.style.display = 'block'
    this.restrictedNoticeTarget.classList.add('show')
  }
}
