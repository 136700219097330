$(document).on('turbo:load', function(){
  var header = $('header');
  if(header.length == 0)
    return;
  var headerTop = header.offset().top;
  var headerBottom = headerTop + 120; // Sub-menu should appear after this distance from top.
  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop(); // Current vertical scroll position from the top
    if (scrollTop > headerBottom) { // Check to see if we have scrolled more than headerBottom
      if (($("#mobile-top-link").is(":visible") === false)) {
          $('#mobile-top-link').fadeIn('slow');
      }
    } else {
      if ($("#mobile-top-link").is(":visible")) {
          $('#mobile-top-link').hide();
      }
    }
  });
});
