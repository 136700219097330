$(document).off('click', "p.reveal");
$(document).on('click', "p.reveal", function () {
  if ($.trim($(this).text()) === 'Hide ▲') {
    $(this).text('Show ▼');
  } else {
    $(this).text('Hide ▲');
  }

  return false;
});

$(document).off('click', ".js-reveal-details");
$(document).on('click', ".js-reveal-details", function () {
  $(this).toggleClass("active").next().slideToggle("slow");

  if ($.trim($(this).text()) === 'Hide Calculator Details ▲') {
    $(this).text('Show Calculator Details ▼');
  } else {
    $(this).text('Hide Calculator Details ▲');
  }

  return false;
});
