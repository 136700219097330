import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "modal", "modalContent" ]

  connect() {
    let anchorElements = document.getElementsByTagName('a')
    let lessonLinks = Array.from(anchorElements).filter( (link) => link.href.includes('Lesson') )
    const data = this.modalTarget.dataset
    const modalTarget = this.modalTarget

    useClickOutside(this, { element: this.modalContentTarget, eventType: 'click' })

    if(data.enabledWclp == "false") {
      for (var i = 0; i < lessonLinks.length; i++) {
        lessonLinks[i].onclick = function(e) {
          modalTarget.classList.add('show')
          modalTarget.style.display = 'block'
          document.body.classList.add('modal-open')
          return false;
        };
      }
    }
  }

  clickOutside(event) {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'
  }

  hideModal() {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'
  }
}
