import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="new-tos-modal"
export default class extends Controller {
  static targets = [ "modal", "newTosForm", "errorMessage"]

  connect() {
    const data = this.modalTarget.dataset

    if(data.showNewTos != 'true') {
      return
    }
    this.showModal()

    this.newTosFormTarget.addEventListener("submit", (event) => {
      event.preventDefault()

      fetch(this.newTosFormTarget.action, {
        method: this.newTosFormTarget.method,
        body: new FormData(this.newTosFormTarget)
      })
      .then(response => response.json())
      .then(data => {
        if(data.error) {
          this.showError()
          return;
        }

        this.hideModal()
      })
      .catch(() => {
        this.showError()
      })
    })
  }

  showModal() {
    this.modalTarget.classList.add('show')
    this.modalTarget.style.display = 'block'
    document.body.classList.add('modal-open')
  }

  showError() {
    this.errorMessageTarget.style.display = "block"
  }

  hideModal() {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'
    document.body.classList.remove('modal-open')
  }
}
