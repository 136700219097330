import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal', 'cancellationModalConfirmation', 'cancellationModalContent']

  cancel_subscription(event) {
    this.modalTarget.style.display = 'block';
  }

  closeModal(event) {
    this.modalTarget.style.display = 'none';
  }

  reloadPage(event) {
    window.location.reload();
  }

  cancellationRequest(event) {

    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch('/cancellation_request', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'X-CSRF_Token': csrfToken }
    })
    .then((response) => {
      const data = response.json();
      if (!response.ok) {
        console.error(data);
      }
      this.cancellationModalConfirmationTarget.style.display = 'block';
      this.cancellationModalContentTarget.style.display = 'none';
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
