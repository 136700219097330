import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="state-bar-number-modal"
export default class extends Controller {
  static targets = [ "modal", "jobRoleForm", "stateBarForm", "officeLocationForm", "thankYouMessage", "otherConfirmation", "errorMessage", "modalTitle", "jobRoleDescription"]

  connect() {
    this.hideThankYouMessage()
    this.hideOtherConfirmation()
    this.hideOfficeLocationForm()

    const data = this.modalTarget.dataset

    if(data.userJobRole != "" && data.userAttorney == 'false' && data.shouldChooseLocation == 'false') {
      return
    }
    if(data.userJobRole == "") {
      this.showModal()
      this.hideStateBarForm()
    }
    else if(data.userAttorney == "true" && data.userStateBarNumber == "") {
      this.showModal()
      this.hideJobRoleForm()
      this.hideError()
      this.showStateBarForm()
    }
    else if(data.shouldChooseLocation == 'true'){
      this.showModal()
      this.hideJobRoleForm()
      this.hideStateBarForm()
      this.showOfficeLocationForm()
    }

    this.jobRoleFormTarget.addEventListener("submit", (event) => {
      event.preventDefault()

      fetch(this.jobRoleFormTarget.action, {
        method: this.jobRoleFormTarget.method,
        body: new FormData(this.jobRoleFormTarget)
      })
      .then(response => response.json())
      .then(data => {
        if(data.error) {
          this.showError(data.error)
          return;
        }
        this.updateTitle("Thank you for submitting!")
        this.hideError()
        if(data.attorney) {
          this.hideJobRoleForm()
          this.showStateBarForm()
        }
        else if(data.job_role == 'Other (not listed)') {
          this.hideJobRoleForm()
          this.showOtherConfirmation()
        } else {
          this.hideJobRoleForm()
          this.updateTitle("Thank you for updating your user profile.")
          setTimeout(function() {
            const modals = document.getElementsByClassName('modal')
            for(const modal of modals) {
              modal.style.display = "none"
            }
          }, 2000);
        }
      })
      .catch(error => {
        this.showError(error.responseJSON.error)
      })
    })

    this.stateBarFormTarget.addEventListener("submit", (event) => {
      event.preventDefault() 

      fetch(this.stateBarFormTarget.action, {
        method: this.stateBarFormTarget.method,
        body: new FormData(this.stateBarFormTarget)
      })
      .then(response => response.json())
      .then(data => {
        if(data.error) {
          this.showError(data.error)
          return;
        }
        this.updateTitle("Thank you for updating your user profile.")
        this.hideError()
        this.hideStateBarForm()
        setTimeout(function() {
          const modal = document.querySelector("#stateBarNumberModal")
          modal.style.display = "none"
        }, 2000);
      })
    })

    this.officeLocationFormTarget.addEventListener("submit", (event) => {
      event.preventDefault()

      fetch(this.officeLocationFormTarget.action, {
        method: this.stateBarFormTarget.method,
        body: new FormData(this.officeLocationFormTarget)
      })
      .then(response => response.json())
      .then(data => {
        if(data.error) {
          this.showError(data.error)
          return;
        }
        this.updateTitle("Thank you for updating your location.")
        this.hideError()
        this.hideOfficeLocationForm()
        setTimeout(function() {
          const modal = document.querySelector("#stateBarNumberModal")
          modal.style.display = "none"
        }, 2000);
      })
    })
  }

  showModal() {
    this.modalTarget.classList.add('show')
    this.modalTarget.style.display = 'block'
    document.body.classList.add('modal-open')
  }

  hideModal() {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'
    document.body.classList.remove('modal-open')
  }

  hideJobRoleForm() {
    this.jobRoleFormTarget.style.display = "none"
  }
  hideOfficeLocationForm() {
    this.officeLocationFormTarget.style.display = "none"
  }
  hideStateBarForm() {
    this.stateBarFormTarget.style.display = "none"
  }
  hideOtherConfirmation() {
    this.otherConfirmationTarget.style.display = "none"
  }
  showStateBarForm() {
    this.stateBarFormTarget.style.display = "block"
  }
  showJobRoleForm() {
    this.jobRoleFormTarget.style.display = "block"
  }
  showOfficeLocationForm() {
    this.officeLocationFormTarget.style.display = "block"
  }
  hideThankYouMessage() {
    this.thankYouMessageTarget.style.display = "none"
  }
  chooseAgain() {
    this.hideOtherConfirmation()
    this.showJobRoleForm()
    this.hideJobRoleDescription()
  }
  showOtherConfirmation() {
    this.otherConfirmationTarget.style.display = "block"
  }
  showError(error) {
    this.errorMessageTarget.innerHTML = error
  }
  hideError() {
    this.errorMessageTarget.innerHTML = ""
  }

  hideJobRoleDescription() {
    this.jobRoleDescriptionTarget.style.display = "none"
  }

  updateTitle(title) {
    this.modalTitleTarget.innerHTML = title;
  }
}
