import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "modal", "modalContent" ]

  connect() {
    useClickOutside(this, { element: this.modalContentTarget, eventType: 'click' })
  }

  clickOutside(event) {
    this.hideModal()
  }

  hideModal() {
    this.modalTarget.classList.remove('show')
    this.modalTarget.style.display = 'none'
    this.openLink()
  }

  showModal() {
    this.modalTarget.classList.add('show')
    this.modalTarget.style.display = 'block'
  }

  openLink() {
    window.open(this.targetUrl, '_blank');
  }

  linkClicked(event) {
    fetch('/users/increment_precedent_popups_seen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(response => response.json())
      .then(data => {
        this.targetUrl = event.target.href
        if (data.precedent_popups_seen == 1 || data.precedent_popups_seen == 25 || data.precedent_popups_seen % 50 == 0) {
          this.showModal()
        }
        else {
          this.openLink()
        }
      })
  }
}
